/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:28:40
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 17:49:26
 */
import PageHeader from './pageHeader.vue'
export default PageHeader
